import { IconType } from 'react-icons';
import { SiGerrit, SiGithub } from 'react-icons/si';
import { ReactComponent as AzureIcon } from '../../assets/icons/azure-repos.svg';
import { ReactComponent as BitbucketIcon } from '../../assets/icons/bitBucket-icon.svg';
import { ReactComponent as GitlabIcon } from '../../assets/icons/gitlab-icon.svg';
import {
  DefineBranchPerRepo,
  InputTypeSetting,
  RadioOptions,
  SettingsByName,
  SettingsIcon,
  SettingsObj,
  SettingsSubSettings,
  SettingsSubType,
} from '../types/settings-types';

export const filterSettingsByType = (
  settingsArray: SettingsByName[],
  settingsType: string,
): SettingsByName[] => {
  const filteredSettingsGroups: SettingsByName[] = [];

  settingsArray.forEach(settingsGroup => {
    const filteredSettings = settingsGroup.settings.filter(
      setting => setting.settingsType === settingsType,
    );

    if (filteredSettings.length > 0) {
      filteredSettingsGroups.push({
        name: settingsGroup.name,
        settings: filteredSettings,
        isDevelopment: settingsGroup.isDevelopment,
        ffKey: settingsGroup.ffKey,
      });
    }
  });

  return filteredSettingsGroups;
};

export const createDropDownObj = (settingSubType: string) => {
  if (settingSubType === SettingsSubType.ScheduledScanHour) {
    const hoursObject = {};
    for (let i = 0; i < 24; i++) {
      if (i === 0) hoursObject[i.toString()] = '12am';
      else if (i === 12) hoursObject[i.toString()] = '12pm';
      else if (i > 12) hoursObject[i.toString()] = `${i - 12}pm`;
      else hoursObject[i.toString()] = `${i}am`;
    }
    return hoursObject;
  }
  return {};
};

export const handleChangeHour = (value: number) => {
  const date = new Date();
  date.setHours(Number(value));
  return date.getUTCHours();
};

export const getLocalHourFromUTCHour = (value: number) => {
  const date = new Date();
  date.setUTCHours(value);
  return date.getHours();
};

export const SettingsIconsMap = new Map<SettingsIcon, IconType>([
  [SettingsIcon.GitHub, SiGithub],
  [SettingsIcon.GitLab, GitlabIcon as IconType],
  [SettingsIcon.Bitbucket, BitbucketIcon as IconType],
  [SettingsIcon.Azure, AzureIcon as IconType],
  [SettingsIcon.Gerrit, SiGerrit],
]);

export const getSubSettingIcon = (icon: SettingsIcon) => {
  return (SettingsIconsMap.get(icon) as IconType) || null;
};

export const getRadioOptions = (setting?: SettingsSubSettings[]) => {
  if (!setting) return [];
  const radioOptions: RadioOptions[] =
    setting.find(s => s.inputTypeSubSetting === InputTypeSetting.Radio)
      ?.radioOptions || [];
  return radioOptions;
};

export const mergeArraysWithoutDuplicatesById = (
  arr1: DefineBranchPerRepo[],
  arr2: DefineBranchPerRepo[],
): string[] => {
  const mergedArray = arr1.reduce(
    (acc: DefineBranchPerRepo[], currentItem: DefineBranchPerRepo) => {
      if (!acc.find(item => item.appId === currentItem.appId)) {
        acc.push(currentItem);
      }
      return acc;
    },
    [...arr2],
  );

  return mergedArray.map(item => item.inputText);
};

export const findValueByKey = (obj: {}, keyToFind: string): string => {
  for (const key in obj) {
    if (key === keyToFind) {
      return obj[key];
    }
  }
  return '';
};
export const getSettingsBySubType = (
  settings: SettingsByName[],
  subType: SettingsSubType,
) => {
  return settings.reduce((acc: SettingsObj | null, curr) => {
    const settings = curr.settings;
    settings.forEach(setting => {
      if (setting.settingsSubType === subType) {
        acc = setting;
      }
    });
    return acc;
  }, null);
};
