import { useAuth0 } from '@auth0/auth0-react';
import { LogName } from '@oxappsec/audit-log-service';
import * as React from 'react';
import { FC } from 'react';
import { useSnapshot } from 'valtio';
import { UserStore } from '../../../../members/stores/user-roles-store';
import {
  RequestAccessSettingsStore,
  getRequestAccessConfiguration,
} from '../../../../request-access/api/request-access-settings-api';
import { auditUserLogin } from '../../../actions/app-login-actions';
import UserAvatar from './UserAvatar';
import UserMenu from './UserMenu';
import { userMenuActions } from './user-menu-actions';

const UserMenuContainer: FC<React.PropsWithChildren<unknown>> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useAuth0();
  const { roles } = useSnapshot(UserStore);

  const { requestAccessConfiguration } = useSnapshot(
    RequestAccessSettingsStore,
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      if (!requestAccessConfiguration) {
        getRequestAccessConfiguration();
      }
    },
    [requestAccessConfiguration],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = React.useCallback(async () => {
    if (user) {
      await auditUserLogin(user, LogName.Logout);
    }

    logout({
      returnTo: window.location.origin,
    });
  }, [logout, user]);

  const showCreateOrgButton = userMenuActions.isShowCreateOrgButton(
    requestAccessConfiguration,
    roles,
  );

  return (
    <>
      <UserAvatar open={open} handleAvatarClick={handleClick} />

      <UserMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        handleLogout={handleLogout}
        isLoading={!requestAccessConfiguration}
        showCreateOrgButton={showCreateOrgButton}
      />
    </>
  );
};

export default UserMenuContainer;
