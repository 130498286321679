import { Nullable } from 'ox-common-types';
import { RequestAccessConfiguration } from '../request-access-types';
import RequestAccessSettingsStore from '../stores/request-access-settings-store';

export const setIsLoading = (isLoading: boolean) => {
  RequestAccessSettingsStore.isLoadingSettings = isLoading;
};

export const setRequestAccessConfiguration = (
  requestAccessConfiguration: Nullable<RequestAccessConfiguration>,
) => {
  RequestAccessSettingsStore.requestAccessConfiguration =
    requestAccessConfiguration;
};
