import { Nullable } from 'ox-common-types';
import {
  MessagingAction,
  MessagingVendorTypes,
  MessagingRecipient,
} from '../types/messaging-types';
import {
  MessagingStore,
  InitialMessagingStore,
} from '../store/messaging-store';
import { IssuePages } from '../../issues/common/types/issues-types';
import { MessagingActionFields } from '@oxappsec/policy-workflow-service';

export const messagingStoreActions = {
  setIsShowMessagingModal: (isOpen: boolean) => {
    MessagingStore.isShowMessagingModal = isOpen;
  },
  setSelectedMessagingVendor: (vendor: Nullable<MessagingVendorTypes>) => {
    MessagingStore.selectedMessagingVendor = vendor;
  },
  setSelectedAction: (action: Nullable<MessagingAction>) => {
    MessagingStore.selectedAction = action;
  },
  setIsLoadingSendMessage: (isLoading: boolean) => {
    MessagingStore.isLoadingSendMessage = isLoading;
  },
  setIsErrorSendingMessage: (isError: boolean) => {
    MessagingStore.isErrorSendingMessage = isError;
  },
  setSelectedIssuesIds: (issueIds: string[]) => {
    MessagingStore.selectedIssuesIds = issueIds;
  },
  setPage: (page: Nullable<IssuePages>) => {
    MessagingStore.page = page;
  },
  setComment: (comment: string) => {
    MessagingStore.comment = comment;
  },
  setSelectedRecipients: (recipients: MessagingRecipient[]) => {
    MessagingStore.selectedRecipients = recipients;
  },
  setIsLoadingRecipients: (isLoading: boolean) => {
    MessagingStore.isLoadingRecipients = isLoading;
  },
  setIsErrorLoadingRecipients: (isError: boolean) => {
    MessagingStore.isErrorLoadingRecipients = isError;
  },
  setAllRecipients: (recipients: MessagingRecipient[]) => {
    MessagingStore.allRecipients = recipients;
  },
  resetStore: () => {
    Object.assign(MessagingStore, InitialMessagingStore);
  },
  setSelectedRecipientsFallback: (recipients: MessagingRecipient[]) => {
    MessagingStore.selectedRecipientsFallback = recipients;
  },
  setIsWorkflow: (isWorkflow: boolean) => {
    MessagingStore.isWorkflow = isWorkflow;
  },
  initInputValuesWorkflow: (values: MessagingActionFields) => {
    if (values) {
      MessagingStore.selectedRecipients = values.recipients;
      MessagingStore.selectedMessagingVendor = values.messagingVendor;
    }
  },
};
