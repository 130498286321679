import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Badge, IconButton, styled } from '@mui/material';
import { Roles } from '@oxappsec/ox-unified-permissions';
import * as React from 'react';
import { FC } from 'react';
import { FaCrown, FaLock, FaUserNinja, FaUserShield } from 'react-icons/fa';
import { FaListCheck } from 'react-icons/fa6';
import { useSnapshot } from 'valtio';
import { UserStore } from '../../../../members/stores/user-roles-store';

const UserAvatar: FC<React.PropsWithChildren<UserAvatarProps>> = props => {
  const { open, handleAvatarClick } = props;

  const { user } = useAuth0();
  const { roles } = useSnapshot(UserStore);

  const BadgeContent = React.useMemo(() => {
    if (!roles) {
      return null;
    }
    const role = roles[0];
    switch (role) {
      case Roles.Admin:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaCrown
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles['Read Only']:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaLock
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles['Dev Manager / Security Champion']:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaUserShield
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles.Developer:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaUserNinja
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles['Policy Manager']:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaListCheck
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      default:
        return null;
    }
  }, [roles]);

  return (
    <Badge
      overlap='circular'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={BadgeContent}
      sx={{ marginInlineEnd: 1 }}>
      <IconButton
        sx={{
          padding: 0,
          '& > div': {
            width: 30,
            height: 30,
            fontSize: '0.75rem',
          },
        }}
        size='small'
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleAvatarClick}>
        <Avatar alt={user?.name} src={user?.picture} />
      </IconButton>
    </Badge>
  );
};

const SmallAvatar = styled(Avatar)(() => ({
  width: 18,
  height: 18,
  border: `3px solid yellow`,
  backgroundColor: 'yellow',
}));

export interface UserAvatarProps {
  handleAvatarClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

export default UserAvatar;
