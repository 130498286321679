import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { RequestAccessConfiguration } from '../request-access-types';

const RequestAccessSettingsStore = createStore<RequestAccessSettingsStoreState>(
  {
    isLoadingSettings: false,
    requestAccessConfiguration: null,
  },
  'Request Access Settings Store',
);

interface RequestAccessSettingsStoreState {
  isLoadingSettings: boolean;
  requestAccessConfiguration: Nullable<RequestAccessConfiguration>;
}

export default RequestAccessSettingsStore;
