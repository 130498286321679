import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { CustomLink, MultiLineEllipsisProps } from 'ox-react-components';
import { useCallback } from 'react';
import { Column } from 'react-data-grid';
import { BiRefresh } from 'react-icons/bi';
import {
  updateTicketsDetailByProvider,
  updateJiraTicketsLatestStatus,
} from '../../../../active-issues/actions/active-issues-actions';
import { JiraTicketRef } from '../../../types/issues-types';
import { TicketingVendorDisplayName } from '../../../../../ticketing-module/types/ticketing-types';
import { Nullable } from 'ox-common-types';

const CellWithTooltip = ({
  title,
  value,
  link,
  ...otherProps
}: {
  title?: Nullable<string>;
  value: string;
  link?: string;
} & Pick<
  MultiLineEllipsisProps,
  'variant' | 'color' | 'fontWeight' | 'fontStyle' | 'fontSize'
>) => (
  <Tooltip
    arrow
    placement='right'
    title={
      title ? (
        <Typography variant='body1' color='text.primary'>
          {title}
        </Typography>
      ) : (
        ''
      )
    }>
    <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
      <Typography variant='caption' {...otherProps}>
        {link ? <CustomLink href={link} value={value} /> : value}
      </Typography>
    </Box>
  </Tooltip>
);
const EStatusCategoryColor = {
  indeterminate: 'blue',
  new: 'gray',
  done: 'green',
} as const;

export const useTableConfig = (): Column<JiraTicketRef>[] => {
  const refetchTicketsStatus = useCallback(() => {
    updateTicketsDetailByProvider();
    updateJiraTicketsLatestStatus();
  }, []);

  return [
    {
      key: 'refresh',
      width: 10,
      name: (
        <Tooltip arrow placement='bottom' title='Refresh'>
          <span>
            <IconButton
              onClick={refetchTicketsStatus}
              size='small'
              color='primary'>
              <BiRefresh size={20} />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
    {
      name: 'Key',
      key: 'key',
      formatter: ({ row }) => (
        <CellWithTooltip value={row.key} link={row.link || row?.ticketLink} />
      ),
    },
    {
      name: 'Provider',
      key: 'provider',
      formatter: ({ row }) => (
        <CellWithTooltip
          value={
            TicketingVendorDisplayName[row.provider] ||
            TicketingVendorDisplayName[row.provider ?? ''] ||
            'Jira'
          }
        />
      ),
    },
    {
      name: 'Project',
      key: 'project',
      formatter: ({ row }) => {
        return (
          <CellWithTooltip
            title={row.fields?.project.key || row.project?.name}
            value={
              row.fields?.project.name || row.project?.name || 'Not Available'
            }
          />
        );
      },
    },
    {
      name: 'Created',
      key: 'created',
      formatter: ({ row }) => (
        <CellWithTooltip
          value={
            row.fields?.created || row.created
              ? formatDistanceToNow(
                  new Date(row.fields?.created || row.created || ''),
                  {
                    addSuffix: true,
                  },
                )
              : ''
          }
          title={
            row.fields?.created || row.created
              ? `${new Date(
                  row.fields?.created || row.created || '',
                ).toLocaleDateString()}, ${new Date(
                  row.fields?.created || row.created || '',
                ).toLocaleTimeString()}`
              : 'Not Available'
          }
        />
      ),
    },
    {
      name: 'Updated',
      key: 'updated',
      formatter: ({ row }) => (
        <CellWithTooltip
          value={
            row.fields?.updated || row.updated
              ? formatDistanceToNow(
                  new Date(row.fields?.updated || row.updated || ''),
                  {
                    addSuffix: true,
                  },
                )
              : ''
          }
          title={
            row.fields?.updated || row.updated
              ? `${new Date(
                  row.fields?.updated || row.updated || '',
                ).toLocaleDateString()}, ${new Date(
                  row.fields?.updated || row.updated || '',
                ).toLocaleTimeString()}`
              : 'Not Available'
          }
        />
      ),
    },
    {
      name: 'Assignee',
      key: 'assignee',
      formatter: ({ row }) => (
        <CellWithTooltip
          title={
            row.fields?.assignee?.name ||
            row.fields?.assignee?.displayName ||
            row.assignTo?.name ||
            'Unassigned'
          }
          value={
            row.fields?.assignee?.emailAddress ||
            row.fields?.assignee?.displayName ||
            row.assignTo?.name ||
            'Unassigned'
          }
        />
      ),
    },
    {
      name: 'Reporter',
      key: 'reporter',
      formatter: ({ row }) => (
        <CellWithTooltip
          title={
            row.fields?.reporter?.name ||
            row.fields?.reporter?.displayName ||
            row.reporter?.name ||
            ''
          }
          value={
            row.fields?.reporter?.emailAddress ||
            row.reporter?.name ||
            'Not Available'
          }
        />
      ),
    },
    {
      name: 'Status',
      key: 'status',
      minWidth: 170,
      formatter: ({ row }) => (
        <CellWithTooltip
          fontSize={10}
          fontStyle='oblique'
          fontWeight={700}
          variant='overline'
          title={row.fields?.status.statusCategory.name || row.status}
          color={
            row.fields?.status.statusCategory.key
              ? EStatusCategoryColor[row.fields?.status.statusCategory.key]
              : ''
          }
          value={row.fields?.status.name || row.status || 'Not Available'}
        />
      ),
    },
  ];
};
