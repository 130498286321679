import { Nullable } from 'ox-common-types';
import { RequestAccessConfiguration } from '../../../../request-access/request-access-types';
import { Roles } from '@oxappsec/ox-unified-permissions';

const isShowCreateOrgButton = (
  requestAccessConfiguration: Nullable<RequestAccessConfiguration>,
  roles: Nullable<Roles[]>,
) => {
  if (roles?.includes(Roles.Admin)) {
    return true;
  }

  if (
    !requestAccessConfiguration ||
    requestAccessConfiguration?.allowUserToCreateOrg
  ) {
    return true;
  }

  return false;
};

export const userMenuActions = {
  isShowCreateOrgButton,
};
