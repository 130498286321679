import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  MessagingAction,
  MessagingRecipient,
  MessagingVendorTypes,
} from '../types/messaging-types';
import { IssuePages } from '../../issues/common/types/issues-types';

export const InitialMessagingStore = createStore<MessagingStoreState>(
  {
    isShowMessagingModal: false,
    selectedIssuesIds: [],
    page: null,
    selectedMessagingVendor: null,
    selectedAction: null,
    selectedRecipients: [],
    isLoadingRecipients: false,
    isErrorLoadingRecipients: false,
    allRecipients: [],
    comment: '',
    isLoadingSendMessage: false,
    isErrorSendingMessage: false,
    selectedRecipientsFallback: [],
    isWorkflow: false,
  },
  'Messaging Store',
);

const baseStore = createStore<MessagingStoreState>(
  {
    ...InitialMessagingStore,
  },
  'Create Message Store',
);

export const MessagingStore = derive(
  {
    isSubmitActionDisabled: get => {
      const { selectedRecipients } = get(baseStore);
      return selectedRecipients.length === 0;
    },
  },
  { proxy: baseStore },
);

interface MessagingStoreState {
  isShowMessagingModal: boolean;
  page: Nullable<IssuePages>;
  selectedIssuesIds: string[];
  selectedMessagingVendor: Nullable<MessagingVendorTypes>;
  selectedAction: Nullable<MessagingAction>;
  selectedRecipients: MessagingRecipient[];
  isLoadingRecipients: boolean;
  isErrorLoadingRecipients: boolean;
  allRecipients: MessagingRecipient[];
  comment: string;
  isLoadingSendMessage: boolean;
  isErrorSendingMessage: boolean;
  selectedRecipientsFallback: MessagingRecipient[];
  isWorkflow: boolean;
}
