import { Nullable } from 'ox-common-types';
import { MessagingAction, MessagingVendorTypes } from './types/messaging-types';

export const messagingUtils = {
  validateSendMessageParams: (params: {
    selectedMessagingVendor: Nullable<MessagingVendorTypes>;
    selectedAction: Nullable<MessagingAction>;
    selectedIssuesIds: string[];
    page: Nullable<string>;
  }) => {
    const { selectedMessagingVendor, selectedAction, selectedIssuesIds, page } =
      params;
    return (
      selectedMessagingVendor &&
      selectedAction &&
      page &&
      (selectedIssuesIds?.length > 0 ||
        selectedAction === MessagingAction.WorkflowsMessage)
    );
  },
};
