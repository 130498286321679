import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { AppPages, navigate } from 'app-navigator';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { CgNotes } from 'react-icons/cg';
import { FaBullhorn } from 'react-icons/fa';
import { MdAdd as AddIcon, MdLogout as LogoutIcon } from 'react-icons/md';
import { openCreateOrgModal } from '../../../../organizations/api/organizations-api';

const UserMenu: FC<React.PropsWithChildren<UserMenuProps>> = props => {
  const {
    open,
    anchorEl,
    handleClose,
    handleLogout,
    isLoading,
    showCreateOrgButton,
  } = props;

  const navigateToReleaseNotes = useCallback(() => {
    navigate(AppPages.ReleaseNotes);
  }, []);

  const navigateToWhatsNew = useCallback(() => {
    navigate(AppPages.WhatsNew);
  }, []);

  return (
    <Menu
      elevation={5}
      id='user-profile-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'user-profile-button',
      }}
      onClick={handleClose}>
      <span>
        {isLoading ? (
          <>
            <MenuItem disabled>
              <ListItemText>Fetching settings...</ListItemText>
            </MenuItem>
            <Divider component='li' />
          </>
        ) : (
          <>
            {showCreateOrgButton && (
              <>
                <MenuItem onClick={openCreateOrgModal}>
                  <ListItemIcon>
                    <AddIcon color='action' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Create Organization</ListItemText>
                </MenuItem>
                <Divider component='li' />
              </>
            )}
          </>
        )}
        <MenuItem onClick={navigateToWhatsNew}>
          <ListItemIcon>
            <FaBullhorn />
          </ListItemIcon>
          <ListItemText>What's New</ListItemText>
        </MenuItem>

        <MenuItem onClick={navigateToReleaseNotes}>
          <ListItemIcon>
            <CgNotes />
          </ListItemIcon>
          <ListItemText>Release Notes</ListItemText>
        </MenuItem>

        <Divider component='li' />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon color='action' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </span>
    </Menu>
  );
};

export interface UserMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  handleLogout: () => void;
  isLoading: boolean;
  showCreateOrgButton: boolean;
}

export default UserMenu;
